<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Abril 2023                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section>
    <section class="d-flex mt-3 mb-3">
      <v-text-field v-model="searchUser" class="me-3 ms-3" style="width: 50%" label="Usuario" dense
        outlined hide-details></v-text-field>
      <v-text-field v-model="searchContract" class="me-3" style="width: 50%" label="Contrato" dense
        outlined hide-details></v-text-field>

      <v-tooltip left color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small color="success" class="me-3" @mousedown.prevent="agregarRelaciones()" fab v-bind="attrs" v-on="on" :disabled="!cargaUsuarios">
            <v-icon>add</v-icon>
          </v-btn>
        </template>
        <span>Agregar relación</span>
      </v-tooltip>

    </section>

    <!-- modal para cargar relaxiones de forma masiva -->
    <v-dialog v-model="dialogAgregarRelaciones" persistent max-width="700px">
      <v-card>
        <v-tabs v-model="tab" background-color="primary" grow dark>
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <v-tab>Agregar relaciones masivas </v-tab>
          <v-tab>Agregar relaciones uno a uno </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card-text class="pa-3">
              <div class="dropbox ma-1" v-show="verIputCSV">
                <input type="file" @change="lectorCSV()" ref="inputUpload" class="input-file" accept=".csv">
                <p :style="{ color: letraCSV }" class="mt-5">{{ textCSV }} </p>
              </div>
              <v-simple-table class="tablaRelaciones ma-1" v-show="verTabla" height="40vh" fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="width: 10%;"> USUARIO </th>
                      <th style="width: 45%;"> CONTRATO </th>
                      <th style="width: 45%;"> VALIDACIÓN </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, i) in csvTabla" :key="i">
                      <td>{{ data[0] }}</td>
                      <td>{{ data[1] }}</td>
                      <td v-if="data.length > 1">
                        <v-icon :color="data[2] == '' ? 'success' : 'error'">
                          {{ data[2] == '' ? 'check_circle' : 'cancel' }}
                        </v-icon>{{ data[2] }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div class="d-flex justify-end mt-5">
                <v-btn text color="error" @click="clear()">Cerrar</v-btn>
                <v-btn v-if="verTabla" color="blue" text @click="volverACargarDocumento">VOLVER</v-btn>
                <v-btn depressed :disabled="desactivarGuardar" @click="guardarRelaciones()" :color="desactivarGuardar ? 'grey' : 'success'">
                  Guardar </v-btn>
              </div>
            </v-card-text>
          </v-tab-item>

          <v-tab-item>
            <v-card-text class="pa-3">
              <div class="d-flex mt-3">
                <v-autocomplete v-model="usuario" style="width: 35%;" :items="usuariosItems" label="Usuario" dense
                  outlined ref="refUsuario" no-data-text="No se encontraron resultados." :rules="[rules.required]"
                  :menu-props="{ maxWidth: 280, maxHeight: 200, offsetY: true }">
                </v-autocomplete>
                <span class="mt-2 mx-2">Relacionar con:</span>
                <v-autocomplete class="contratos" ref="refContrato" v-model="contratos" style="width: 35%;" :items="contratoItems" label="Contratos"
                  small-chips deletable-chips multiple dense outlined no-data-text="No se encontraron resultados."
                  :menu-props="{ maxWidth: 280, maxHeight: 200, offsetY: true }" :rules="[v => !!v.length || 'Campo obligatorio.']">
                  <template #selection="{ item, index }">
                    <v-chip v-if="item" :key="index" small class="chip" :title="item" close @click:close="removeChip(index)">
                      {{ truncateText(item, index) }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </div>
              <div class="d-flex justify-end">
                <v-btn text color="error" @click="clear()">Cerrar</v-btn>
                <v-btn depressed @click="guardarRelacionUnoHerinco()" color="success" :disabled="!formValid">
                  Guardar </v-btn>
              </div>
            </v-card-text>
          </v-tab-item>
          <div v-if="overlay" class="overlay">
            <v-alert class="alerta" persistent transition="dialog-bottom-transition">
              <div class="d-flex justify-center">
                <v-icon class=" rotate-animation" color="white" size="30">rotate_right</v-icon>
              </div>
              <span class="d-flex justify-center">Guardando</span>
            </v-alert>
          </div>
        </v-tabs-items>
      </v-card>
    </v-dialog>

    <!-- tabla donde muestra busqueda de realaciones -->
    <v-data-table class="tablaRelaciones mx-3" height="64vh" :loading="cargandoDatos"
      :server-items-length="totalElementos" :headers="tableHeaders" :page="pagina" :items-per-page="itemsPorPagina"
      :items="listarUsuarioContratos" @update:items-per-page="(i) => itemsPorPagina = i" @update:page="(p) => pagina = p"
      :footer-props="footerProps" fixed-header>

      <template v-slot:[`item.contrato`]="{ item }">
        {{ item.contrato.idContrato }} - {{ item.contrato.nombreContrato }}
      </template>

      <!-- agrega chip de estado a cada una de las relaciones -->
      <template v-slot:[`item.eliminado`]="{ item }">
        <div class="d-flex align-center">
          <v-icon :color="item.eliminado === false  ? 'green darken-2' : 'red'">
            {{ item.eliminado === false  ? 'check_circle' : 'cancel' }}
          </v-icon>
          {{ item.eliminado === false  ? 'Activo' : 'Inactivo' }}
        </div>
      </template>

      <!-- agrega boton para cambiar estado a cada una de las relaciones -->
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip v-if="item.contrato.eliminado === true || item.usuarios.estadoUsuario === 'false'" bottom color="gray">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon color="gray" v-bind="attrs" v-on="on">
              <v-icon>{{ item.eliminado === false  ? 'disabled_by_default' : 'fact_check' }}</v-icon>
            </v-btn>
          </template>
          <span>Usuario y/o contrato inactivo</span>
        </v-tooltip>
        <v-tooltip v-else bottom :color="item.eliminado === false ? 'error' : 'success'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon :color="item.eliminado === false ? 'error' : 'success'" 
              @click="modalActivoDesactivo(item)" v-bind="attrs" v-on="on">
              <v-icon>{{ item.eliminado === false  ? 'disabled_by_default' : 'fact_check' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ !item.eliminado ? 'Inactivar' : 'Activar' }}</span>
        </v-tooltip>
      </template>

      <!-- Botón para ver la trazabilidad -->
      <template v-slot:[`item.trazabilidad`]="{ item }">
        <v-tooltip bottom color="blue">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon color="blue" @click="listarTrazabilidad(item)" v-bind="attrs" v-on="on">
              <v-icon>backup_table</v-icon></v-btn>
          </template>
          <span>Trazabilidad</span>
        </v-tooltip>
      </template>

      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- modal para confimar activar o desactivar relacion -->
    <v-dialog v-model="dialogEstado" max-width="320px" persistent>
      <v-card>
        <v-card-title class="fondoHeder">
          {{ relacionSeleccionada.eliminado === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?' }}
        </v-card-title>
        <div v-if="cargando" class="pa-4">
          <p class="text-center ma-0">
            Por favor espere un momento...
            <v-icon :class="{ 'rotate-animation': cargando}" large> rotate_right</v-icon>
          </p>
        </div>
        <div v-else class="d-flex justify-end pa-4">
          <v-btn color="error" text @click="dialogEstado = false"> No </v-btn>
          <v-btn color="success" depressed @click="actualizarEstadoHerinco(relacionSeleccionada, estadoRelacionActualizar)">
            Si </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <ModalTrazabilidad :trazabilidadData="trazabilidadData" :trazaDe="trazaDe" />

  </section>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import Papa from 'papaparse';
import { mapMutations, mapState } from "vuex";
import ModalTrazabilidad from '@/components/ModalTrazabilidad.vue';
import moment from 'moment';

export default {
  name: "UserXContract",
  components: { ModalTrazabilidad },

  data() {
    return {
      codigoEmpresa: 1,
      actualizoUsuario: "",
      dialogEstado: false,
      dialogAgregarRelaciones: false,
      verIputCSV: true,
      textCSV: "Arrastre o haga click en este recuadro para subir un archivo .CSV",
      letraCSV: "black",
      desactivarGuardar: true,
      cargandoDatos: false,
      listarUsuarioContratos: [],
      itemsPorPagina: 10,
      pagina: 1,
      totalElementos: 0,
      searchUser: "",
      searchContract: "",
      relacionSeleccionada: {},
      estadoRelacionActualizar: "",
      verTabla: false,
      validarData: null,
      usuario: null,
      contratos: [],
      csvTabla: [],
      csvData: [],
      usuariosItems: [],
      dataUsuarios: [],
      contratoItems: [],
      dataContratos: [],
      tab: null,
      footerProps: {
        "show-first-last-page": true,
        "items-per-page-text": "Ítems por página",
        "items-per-page-options": [10, 20, 30, 40],
        "show-current-page": true,
      },
      absolute: true,
      overlay: false,
      // los values del tableHeaders son los keys del json del initialize
      tableHeaders: [
        { text: "USUARIO", value: "usuarios.usuario", sortable: false, width: "10%"},
        { text: "NOMBRE", value: "usuarios.nombre", sortable: false, width: "30%" },
        { text: "CONTRATO", value: "contrato", sortable: false, width: "30%" },
        { text: "ESTADO", value: "eliminado", sortable: false, width: "10%" },
        { text: "ACCIONES", align: "center", value: "acciones", sortable: false, width: "10%" },
        { text: "TRAZABILIDAD", value: "trazabilidad", align: "center", sortable: false, width: "10%" },
      ],
      trazabilidadData: [],
      trazaDe: "",
      cargando: false,
      rules: {
        required: v => !!v || 'Campo requerido.',
      },
      cargaUsuarios: false,
    };
  },
  computed: {
    ...mapState(["auth", "notify", "busy", "trazabilidad", "enterprise"]),
    formValid() {
      return this.usuario !== "" && this.usuario !== null && this.contratos.length > 0;
    },
  },
  watch: {
    "searchUser": function () { this.pagina = 1; this.getRelacionesHerinco(); },
    "searchContract": function () { this.pagina = 1; this.getRelacionesHerinco(); },
    "itemsPorPagina": function () { this.getRelacionesHerinco(); },
    "pagina": function () { this.getRelacionesHerinco(); },
  },
  mounted() {
    this.actualizoUsuario = this.auth.username.toUpperCase();
    this.trazabilidad.visible = false;
    this.getUsuariosHerinco();
    this.trazabilidad.visible = false
    this.getContratoHerinco();
    this.getRelacionesHerinco();
  },
  methods: {
    ...mapMutations([
      "showSuccess",
      "showError"
    ]),
    truncateText(text, index) {
      if (index === 0) {
        return text.text.length > 23 ? text.text.substring(0, 23) + '...' : text.text;
      } else {
        return text.text.length > 25 ? text.text.substring(0, 25) + '...' : text.text;
      }
    },
    removeChip(index) {
      this.contratos.splice(index, 1);
    },
    agregarRelaciones() {
      this.letraCSV = "black";
      this.textCSV = "Arrastre o haga click en este recuadro para subir un archivo .CSV";
      // this.getContratoHerinco();
      // this.getUsuariosHerinco();
      this.dialogAgregarRelaciones = true;
    },
    // metodo para listar las relaciones
    getRelacionesHerinco() {
      this.listarUsuarioContratos = [];
      this.cargandoDatos = true;
      this.$http.get(`msa-administration/api/usuario-contrato/listar-relaciones`, {
        params: {
          usuario: this.searchUser,
          contrato: this.searchContract,
          idEmpresa: this.enterprise.code,
          page: this.pagina - 1,
          size: this.itemsPorPagina
        }
      }).then(({ data }) => {
        this.listarUsuarioContratos = data.content;
        this.totalElementos = data.totalElements;
        this.cargandoDatos = false;
      }).catch(err => console.log(err));
    },
    getContratoHerinco() {
      this.contratoItems = [];
      this.dataContratos = [];
      this.$http.get(`msa-administration/contrato/empresa/${this.enterprise.code}`)
        .then(({ data }) => {
          this.dataContratos = data;
          data.forEach(contrato => {
            this.contratoItems.push({ text: `${contrato.idContrato}-${contrato.nombreContrato}`, value: contrato.idContrato })
          })
        })
        .catch(err => console.log(err));
    },
    getUsuariosHerinco() {
      this.usuariosItems = [];
      this.dataUsuarios = [];
      this.$http.get(`msa-administration/api/usuario-empresa/empresa/${this.enterprise.code}`).then(({ data }) => {
        this.dataUsuarios = data;
        data.forEach(usuario => { this.usuariosItems.push({ text: `${usuario.usuarios.usuario} - ${usuario.usuarios.nombre}`, value: usuario.usuarios.usuario.trim().toLowerCase() }) });
        this.cargaUsuarios = true;
      }).catch(err => console.log(err));
    },
    // metodo para cambiar el estado de una relacion
    actualizarEstadoHerinco(dataRelacion, estado) {
      this.cargando = true;
      const estadoNuevo = estado === 'A' ? 'B' : 'A';
      this.$http.put(`msa-administration/api/DpRusco/actualizar-estado`, 
      { rmtUsco: dataRelacion.idUsco,
        actEsta: estadoNuevo, 
        actUsua: this.actualizoUsuario, 
        actHora: new Date() 
      }).then(() => {
        this.showSuccess("El estado de la relación ha sido actualizado correctamente.");
        this.getRelacionesHerinco();
        this.dialogEstado = false;
        this.cargando = false;
      }).catch(err => this.showError(err));
    },
    modalActivoDesactivo(dataRelacion) {
      this.dialogEstado = true;
      this.relacionSeleccionada = dataRelacion;
      dataRelacion.eliminado === true ? this.estadoRelacionActualizar = "B" : this.estadoRelacionActualizar = "A";
    },

    async validarExistencia(contrato, usuario, empresa) {
      const existe = await this.$http.get(`msa-administration/api/DpRusco/valida-relacion/${contrato}/${usuario}/${empresa}`);
      return existe.data ? { existe: true } : { existe: false };
    },

    async validarUsuarioHerinco(usuario) {
      const existe = await this.$http.get(`msa-administration/api/hdUsua/existe-usuario/${usuario}`);
      return existe.data ? { existe: true, usuario } : { existe: false , usuario };
    },

    volverACargarDocumento() {
      this.csvData = [];
      if (this.$refs.inputUpload) {
        this.$refs.inputUpload.value = null;
      }
      this.verTabla = false;
      this.verIputCSV = true;
      this.desactivarGuardar = true;
    },

    async lectorCSV() {
      this.csvTabla = [];
      const archivo = this.$refs.inputUpload.files[0];
      if (archivo.type != "text/csv") {
        this.letraCSV = "red";
        return this.textCSV = "Formato incorrecto, debe subir o arrastrar un archivo .CSV";
      }

      const registrosDuplicados = {};

      Papa.parse(archivo, {
        delimiter: ";",
        complete: async ({ data }) => {
          data.forEach((fila) => {
            if (fila.length > 1) {
              const filaReducida = [fila[0], fila[1]];
              this.csvData.push(filaReducida);
            }
          });

          this.textCSV = "Validando la información del archivo, espere un momento..."

          const validaciones = this.csvData.map(async (fila) => {
            fila[2] = "";

            if (!fila[0].trim()) fila[2] += "- El usuario no puede estar vacío. ";
            if (!fila[1]) fila[2] += "- El contrato no puede estar vacío. ";
            if (fila[2] !== "") return;

            const combinacion = `${fila[0]}-${fila[1]}`;
            if (registrosDuplicados[combinacion]) fila[2] += "- Registro duplicado.";
            registrosDuplicados[combinacion] = true;
            
            const usuarioHerinco = await this.validarUsuarioHerinco(fila[0]);
            if (!usuarioHerinco.existe) fila[2] += "- El usuario no existe en Herinco.";
            
            const usuario = this.dataUsuarios.find(item => item.usuarios.usuario == fila[0].trim());
            const contrato = this.dataContratos.find(item => item.idContrato == fila[1]);
            if (contrato) {
              fila[1] = `${contrato.idContrato}-${contrato.nombreContrato}`;
              if (contrato.eliminado === true) fila[2] += "- El contrato está inactivo.";
            } else {
              fila[2] += "- El contrato no existe.";
            }

            if (usuario) {
              fila[0] = usuario.usuarios.usuario;
              if (usuario.usuarios.estadoUsuario == "false") fila[2] += "- El usuario está inactivo.";
            } else {
              fila[2] += "- El usuario no existe.";
            }

            if (usuario && contrato) {
              const respuesta = await this.validarExistencia(contrato.idContrato, usuario.usuarios.usuario, this.enterprise.code);
              if (respuesta.existe) fila[2] += "- La relación ya existe. ";
            }

          });
          await Promise.all(validaciones)
          .then(() => {
            const validarGuardar = this.csvData.find(valida => valida[2] != "");
            validarGuardar ? this.desactivarGuardar = true : this.desactivarGuardar = false;
            this.csvTabla = this.csvData;
            this.verIputCSV = false;
            this.verTabla = true;
            this.textCSV = "Arrastre o haga click en este recuadro para subir un archivo .CSV";       
          })
          .catch((error) => {
            this.showError(error);
            this.textCSV = "Arrastre o haga click en este recuadro para subir un archivo .CSV"; 
          });
        },
      });
    },
    async guardarRelaciones() {
      this.desactivarGuardar = true;
      let errorEncontrado = false;
      for (const data of this.csvTabla) {
        if (data[0] != "" && data[1] != "") {
          const usuario = this.usuariosItems.find(item => item.value == data[0].toLowerCase());
          const contrato = this.contratoItems.find(item => item.text == data[1]);
          const usuarioGuardar = !usuario ? "Usuario erróneo" : usuario.value;
          const contratoGuardar = !contrato ? "Contrato erróneo" : contrato.value;

          if (usuarioGuardar === "Usuario erróneo" || contratoGuardar === "Contrato erróneo") {
            errorEncontrado = true;
            continue;
          }
          
          try {
            this.overlay = true;
            await this.$http.post("msa-administration/api/DpRusco/guardar-relacion", {
              codEmpr: this.enterprise.code,
              codUsua: usuarioGuardar,
              rmtCont: contratoGuardar,
              actEsta: "A",
              actUsua: this.actualizoUsuario,
              actHora: new Date(),
            });
          }
          catch (err) {
            this.showError(err);
          }
        }
      }
      
      if (errorEncontrado) {
        this.showError("No se pueden guardar relaciones con errores.");
      } else {
        this.clear();
        this.overlay = false;
        this.getRelacionesHerinco();
        this.showSuccess("Relaciones agregadas correctamente.");
      }
    },
    async guardarRelacionUnoHerinco() {
      const validacionData = [];
      const contratosInactivos = [];
      let usuarioInactivo = false;
      const usuario = this.dataUsuarios.find(item => item.usuarios.usuario.toLowerCase() === this.usuario.toLowerCase());
      if (usuario && usuario.usuarios.estadoUsuario === "false") {
        usuarioInactivo = false;
      }

      if (usuarioInactivo) {
        this.showError(`El usuario ${this.usuario.toLowerCase()} está inactivo.`);
        return;
      }

      for (const contratoId of this.contratos) {
        const contrato = this.dataContratos.find(item => item.idContrato === contratoId);
        if (contrato && contrato.eliminado === true) {
          contratosInactivos.push(contratoId);
        } else {
          const respuesta = await this.validarExistencia(contratoId, this.usuario, this.enterprise.code);
          if (respuesta.existe) {
            validacionData.push({ idContrato: contratoId, usuario: this.usuario });
          }
        }

        const usuario = this.dataUsuarios.find(item => item.usuario === this.usuario)
        if (usuario) {
          usuarioInactivo.push(this.usuario)
        }

        if (contratosInactivos.length > 0) {
          contratosInactivos.forEach((contratoId, index) => {
            setTimeout(() => {
              const contrato = this.contratoItems.find(item => item.value === contratoId);
              this.showError(`El contrato ${contrato.text} está inactivo.`)
            }, index * 2000);
          });
        } else if (validacionData.length > 0) {
          validacionData.forEach((res, index) => {
            setTimeout(() => {
              const contrato = this.contratoItems.find(item => item.value === res.idContrato);
              const usuario = this.usuariosItems.find(item => item.value == res.usuario.toLowerCase());
              this.showError(`La relación ${usuario.text} - ${contrato.text} ya existe.`);
            }, index * 2000);
          });
        } else {
          try {
            this.overlay = true;
            for (const contratoId of this.contratos) {
              await this.$http.post("msa-administration/api/DpRusco/guardar-relacion", {
              codEmpr: this.enterprise.code,
              codUsua: this.usuario.toUpperCase(),
              rmtCont: contratoId,
              actEsta: "A",
              actUsua: this.actualizoUsuario,
              actHora: new Date(),
            });
            }
            this.clear();
            this.overlay = false;
            this.getRelacionesHerinco();
            this.showSuccess("Relaciones agregadas correctamente.");
          } catch (err) {
            this.showError(err);
          }
        }
      }
    },
    clear() {
      this.csvData = [];
      this.usuario = null;
      this.contratos = [];
      if (this.$refs.inputUpload) {
        this.$refs.inputUpload.value = null;
      }
      if (this.$refs.refUsuario) {
        this.$refs.refUsuario.reset();
      }
      if (this.$refs.refContrato) {
        this.$refs.refContrato.reset();
      }
      this.verTabla = false;
      this.verIputCSV = true;
      this.desactivarGuardar = true;
      this.dialogAgregarRelaciones = false;
      this.overlay = false;
    },
    listarTrazabilidad(relacion) {
      this.$http.get(`msa-administration/api/usuario-contrato/listarTraza/${relacion.idUsco}`)
        .then(({ data }) => {
          data.forEach(element => {
            element.time = this.formatoFechaTrazabilidad(element.time);
            if (element.campo == 'eliminado') element.campo = 'Estado';
            if (element.campo == 'usuario') element.campo = 'Usuario';
            if (element.campo == 'nombre_contrato') element.campo = 'Contrato';
            if (element.anterior === true) element.anterior = 'Inactivo';
            if (element.anterior === false) element.anterior = 'Activo';
            if (element.nuevo === true) element.nuevo = 'Inactivo';
            if (element.nuevo === false) element.nuevo = 'Activo';
          })
          this.trazabilidad.visible = true;
          this.trazaDe = `${relacion.usuarios.usuario} - ${relacion.contrato.nombreContrato}`
          this.trazabilidadData = data.reverse();
        }).catch(err => console.log(err));
    },
    formatoFechaTrazabilidad(dateTimeString) {
      return moment(dateTimeString).format('DD-MM-YYYY h:mm a');
    },
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.fondoHeder {
  background-color: #1867c0 !important;
  color: white;
}

::v-deep .tablaRelaciones div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .tablaRelaciones div table thead tr th span {
  font-weight: bold;
  color: black !important;
}

th {
  color: black !important;
}

.dropbox {
  outline: 2px dashed grey;
  outline-offset: -10px;
  color: rgb(0, 0, 0);
  background: lightcyan;
  padding: 1rem;
  height: 100px;
  position: relative;
  cursor: pointer;
}

.dropbox p {
  margin-top: .7rem;
  text-align: center;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}

.alerta {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 15vw;
}

.rotate-animation {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::v-deep .contratos .v-select__selections {
  max-height: 25vh;
  overflow: auto;
  padding: 4px 0 2px 0 !important;
  margin: 5px 0 1px 0 !important;
}

::v-deep .contratos .v-input__control .v-input__slot {
 padding: 0 0 0 8px !important;
}

::v-deep .contratos .v-input__control .v-input__append-inner {
  padding: 0 !important;
  margin-top: 10.5px !important;
}
</style>